.chatbox_scroll {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 495px;
  margin-right: -10px;
  display: "flex";
  flex-direction: "column-reverse";

}

.chatbox_scroll::-webkit-scrollbar {
  width: 7px;
}

.chatbox_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.chatbox_scroll::-webkit-scrollbar-thumb {
  background: #e9e9ea;
  border-radius: 20px;
}

.chatbox_scroll::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* Message */
.message_scroll {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}
@media screen and (max-width:991px){
  .message_scroll{
    height:100vh!important;
  }
  .message_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey!important;
  border-radius: 0px!important;
}
}
@media screen and (max-width:767px){
  .message_scroll{
    height:auto!important;
  }
  .message_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey!important;
  border-radius: 0px!important;
}
}
.message_scroll::-webkit-scrollbar {
  width: 7px;
}

.message_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


.message_scroll::-webkit-scrollbar-thumb {
  background: #245e52;
  border-radius: 20px;
}

.message_scroll::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.active_link>.nav-item>.active {
  background-color: #fdfdfd;
  box-shadow: none;
}

.search_bar {
  margin-top: 10px;
  position: relative;
  /* bottom: 0px; */
}

.circle {
  height: 50px;
  width: 50px;
  background-color: green;
  border-radius: 50%;
}

.searchBar {
  z-index: -4;
}
@media screen and (max-width:767px) {
  .chat_border_mobile{
    border:none!important;
  }
  /* .mobile_chat_hide{
    display: none;
  } */
}
@media screen and (max-width:991px) and (min-width:767px) {
  .tablet_width{
    width:50%!important;
  }
}
/* @media screen and (max-width:991px) {
  .desktop_message_tab{
    display: none!important;
  }
}
@media screen and (min-width:992px) {
  .mobile_tablet_tab{
    display: none!important;
  }
} */