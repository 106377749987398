.progressbar {
  height: 10px;
  background-color: white;
  margin-bottom: 50px;
  border-radius: 10px;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color:#245D51;
  border-radius: 10px;
  transition: 1s ease-in;
}
.react-dropdown-select-option{
  background:#FBA93E!important;
}
.css-z2hyoe-ItemComponent.react-dropdown-select-item-selected{
  background:#FBA93E!important;

}
/* .css-13cymwt-control{
  width: auto;
}
.css-3w2yfm-ValueContainer{
  width: auto;
}
.css-1p3m7a8-multiValue{
   width:auto;
} */

.react-dropdown-select-input::placeholder{
      font-size: 0.755rem;
}
