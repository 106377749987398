.signup_form {
    display: flex;
    flex-direction: column;
    /* padding-left: 10%; */
  }
  .sign_up_page {
    height: 100vh;
    align-items: center;
  }
  .signup_resolution {
    height: 100vh;
    align-items: center;
    padding-left: 10%;
  }
  .home_naviagtion {
    align-items: center;
    position: absolute;
    top: 1%;
    left: 20px;
    font-size: 24px;
    padding: 12px;
  }
  @media screen and (max-width: 991px) {
    .display_hidden_medium {
      display: none;
    }
    .signup_form {
      display: flex;
      flex-direction: column;
      padding-left: 0%!important;
    }
    .resolution_width{
      width:100%!important;
    }
    .signup_resolution{
        padding-left: 10%!important;
        padding-right: 10%!important;
    }
    .mobile_width_signup {
        width: 100% !important;
      }
  }
  @media screen and (max-width: 436px) {
    .home_naviagtion {
      align-items: center;
      position: relative;
      top: 0%;
      left: -10%;
      font-size: 24px;
    }
  }
  @media screen and (max-width: 360px) {
    .home_naviagtion {
      align-items: center;
      position: relative;
      top: 0%;
      left: -10%;
      font-size: 24px;
    }
    .signup_form {
      display: flex;
      flex-direction: column;
      padding: 0%;
    }
    .signup_resolution {
      height: 20vh;
      align-items: center;
      padding-left: 0%;
    }
    .sign_up_page {
      height: 20vh;
      align-items: center;
    }
  }
  @media screen and (max-width: 760px) {
    .mobile_width_signup {
      width: 100% !important;
    }
    .signup_resolution{
        padding-left: 0%;
        padding-right: 0%;
    }
    .signup_form{
        padding-left: 2%;
        padding-right: 2%;
    }
  }
  