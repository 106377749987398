.side_menus a {
  text-decoration: none;
  color: #245D51;
}

.arrow {
  position: absolute;
  top: 0%;
}

.right {
  right: 0;
}

.left {
  right: 10%;
}

@media screen and (max-width:767px) {
  .dashboard_border {
    border: none !important;
  }
}