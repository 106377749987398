.connection-tab>.nav-item>.active>div{
    border-bottom: 4px solid #FBA93E;
     width: 20;
    white-space: noWrap;
     font-weight: 800;
      cursor: pointer;
       color: black;
       font-size: 1.2rem;
}
.profile-tab>.nav-item>.active>div{
    border-bottom: 4px solid #FBA93E;
     width: 20;
    white-space: noWrap;
     font-weight: 800;
      cursor: pointer;
       color: black;
       font-size: 1.2rem;
}