.connection-tab>.nav-item>.active{
    background:#FBA93E;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.connection-tab>.nav-item{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
@media screen and (max-width:767px) {
    .connection-tab>.nav-item>.active{
        border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    }
    .connection-tab>.nav-item{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}
.edit-material-tab>.nav-item>.active{
    background:#FBA93E;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}