body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scrollbar-color: #245D51 white; */
}
body::-webkit-scrollbar {
  width: 13px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: #245D51 white;
}
body::-webkit-scrollbar-track {
  background: white;
}
body::-webkit-scrollbar-thumb {
  background-color: #245D51 ;
  border-radius: 10px;
  border: 3px solid white;
}

code {
  font-family: 'Montserrat',
    
}
#react-select-5-placeholder{
  font-size:0.7rem;
}
.css-13cymwt-control{
height: 20px;
white-space: nowrap;
}
.recent_request_clamp{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow:ellipsis;
}