.drop-file-input {
    position: relative;
    width: 100%;
    /* height: 200px; */
    border: 2px dotted #FDCD8F!important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF6EC!important;
    color: black!important;
}
.form-check-input:checked{
    background-color:#FDCD8F!important;
    border-color:#FDCD8F!important;
    }
.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}
.toastM {
    background: #EDFBD8!important;
    color: #2B641E;
    font-weight: 500;
   
}
.toastU{
    background-color: #EDFBD8!important;
    font-weight: 500;
    color:yellow;

}
.drop-file-input__label {
    font-weight: 600;
    color: white;
    font-size: 0.9rem;
    /* font-weight: 600; */
    
   
}

.drop-file-input__label img {
    align-items: center;
    width: 22px!important;
}

.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    /* padding: 15px; */
    border-radius: 20px;
}

.drop-file-preview__item img {
    width: 50px;
    margin-right: 20px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: var(--box-bg);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
