.notification_active_link>.nav-item>.active {
    background-color: transparent;
    border-bottom: 3px solid !important;
    color: #245D51;
    box-shadow: none;
}

@keyframes fadeIn {
    
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInDown {
    opacity: 0;
	animation: fadeIn 1s ease-in both;
    animation-delay: 1s;
}