input[type=checkbox] {
    accent-color: #FBA93E;
  }

  #place::placeholder { /* Most modern browsers support this now. */
   color:    white;
   
}
  #place{
    caret-color: #FBA93E;
    
  }