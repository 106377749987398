.slideshow-wrapper {
  position: relative;
  float: left;
  background-size: cover;
}

.mentor_subject {
  position: absolute;
  color: #fff;
  font-size: 0.7rem;
  bottom: 0%;
  left: 0;
  right: 0;
  z-index: 2;
}
.mentor_name {
  position: absolute;
  color: #fff;
  font-size: 1rem;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 2;
}
.slideshow-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(73, 121, 111, 0),
    rgba(36, 93, 81, 0.6)
  );
  border-radius: 3%;
}

.gradient-overlay {
  background: linear-gradient(
    180deg,
    rgba(73, 121, 111, 0) 56.77%,
    rgba(36, 93, 81, 0.6) 90.1%
  );
  mix-blend-mode: normal;
  border-radius: 8px;
}
