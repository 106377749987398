@media screen and (max-width: 814px) and (min-width:530px) {
    .mentor_header_content{
        margin-bottom: 220px;
    }
    .mentor_card{
        width:50%!important;
        position: absolute;
        top:25%!important;
    }
}
@media screen and (max-width:529px) {
   
    .mentor_card{
        width:90%!important;
        margin-right:10px;
    }
    .mentor_mbl_not_show{
        display: none;
    }
    .mentor_header_content{
        margin-bottom: 270px;
    }
    .mentor_card{
        position: absolute;
        top:20%!important;
    } 
    .mentor_mobile{
        display: flex!important;
    }
}

.mentor_header_content{
    background-color: #49796F;
    width: 100%;
}
.mentor_card{
    font-Size:0.7rem;
    width:15%;
    position: absolute;
    top:30%;
    border: 2px solid #49796F;
}
@media screen and (min-width:531px) {
    .mentor_mobile{
    display: none!important;
}
}
