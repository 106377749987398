@media screen and (max-width:352px) {
    .create_pro_outline {
        width: 90%;
        margin-left: 5%;
    }

    .male_btn {
        width: 100% !important;
    }

    .female_btn {
        width: 100% !important;
    }

    .others_btn {
        width: 100% !important;

    }

}

@media screen and (max-width:422px) {
    .create_pro_outline {
        width: 90%;
        margin-left: 5%;
    }

    .male_btn {
        width: 100% !important;
    }

    .female_btn {
        width: 100% !important;
    }

    .others_btn {
        width: 100% !important;
    }

}

@media screen and (max-width:991px) {
    .create_pro_outline {
        width: 90%;
        margin-left: 5%;
    }

    .male_btn {
        width: 100%;
    }

    .female_btn {
        width: 100%;
    }

    .others_btn {
        width: 100%;
    }
    .app_logo{
        text-align: center;
    }
    .home_btn{
        display: none;
    }
}