
@media screen and (max-width: 576px) {
.main{
    
    margin-left: 12px!important;
    width:100%!important;
}
}
@media screen and (max-width:991px){
   .main{
    width: 50%;
    margin-left: 25%;
   }
}
@media screen and (max-width:1120px){
    .main{
     width:25rem;

     /* margin-left: 25%; */
    }
 }