 @media screen and (max-width: 814px) and (min-width:530px) {
    .profile_header_content{
        margin-bottom: 200px;
    }
    .profile_card{
        width:50%!important;
    }
}
@media screen and (max-width:529px) {
   
    .profile_card{
        width:90%!important;
        margin-right:10px;
    }
    .profile_mbl_not_show{
        display: none;
    }
    .profile_header_content{
        margin-bottom: 250px;
    }
    .profile_card{
        position: absolute;
        top:20%!important;
    } 
    .profile_mobile{
        display: flex!important;
    }
}

.profile_header_content{
    background-color: #49796F;
    width: 100%;
}
.profile_card{
    font-Size:0.7rem;
    width:15%;
    position: absolute;
    top:30%;
    border: 2px solid #49796F;
}
@media screen and (min-width:531px) {
    .profile_mobile{
    display: none!important;
}
}
