@media screen and (max-width: 991px){
    .side_menus{
        display: none;
    }
    .desktop_topbar{
        display: none;
    }
    
}
@media screen and (min-width: 992px){
    .bottom_menu{
        display: none;
    }
   .sidebar_height{
    height:100vh;
   }
}
