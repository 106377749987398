.mentors_name{
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: 800;
  
  }
  .single_line_clamp{
    display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  }
  .full_name_mgs{

  /* white-space: nowrap!important; 
  width: 100px!important; 
  overflow: hidden!important;
  text-overflow: ellipsis;  */
  }
  /* #chat_scroll {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 350px;
  }
  
  #chat_scroll::-webkit-scrollbar {
    width: 7px;
  }
  
  #chat_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  #chat_scroll::-webkit-scrollbar-thumb {
    background: #245E52; 
    border-radius: 20px;
  }
  
  #chat_scroll::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }
   */
  